import { GlobalConfig } from '@/types/config'

export const siteConfig: GlobalConfig = {
  name: 'Helios',
  description:
    'Turn your leads into customers. Helios provides all the tools to help you craft perfect SMS campaigns and automate the rest.',
  url: 'https://sendhelios.com',
  ogImage: 'https://i.ibb.co/r7WdcCQ/og.png',
  links: {
    twitter: 'https://twitter.com/sendhelios'
  },
  mainNav: [
    {
      title: 'Features',
      href: '/features'
    },
    {
      title: 'Pricing',
      href: '/pricing'
    },
    {
      title: 'Help Center',
      href: '/help-center'
    },
    {
      title: 'Contact',
      href: '/contact'
    }
    // {
    //   title: "Resources",
    //   href: "/resources",
    //   subNav: [
    //     {
    //       title: 'Help Center',
    //       href: '/help'
    //     },
    //     {
    //       title: 'Blog',
    //       href: '/blog'
    //     }
    //   ]
    // },
    // {
    //   title: "Changelog",
    //   href: "/changelog",
    // },
  ]
}