'use client'

import useScrollPosition from "@/hooks/use-scroll-position"
import { cn } from "@/lib/utils"

export function StickyHeader(props: { children: React.ReactNode }) {
  const scrollPosition = useScrollPosition()
  return (
    <header
      className={cn(
        // scrollPosition > 10 ? 'bg-[rgba(255,255,255,0.75)] backdrop-blur-xl blur-none' : 'bg-[rgba(255,255,255,0)]',
        scrollPosition > 0 ? 'bg-background is-sticky' : 'bg-transparent',
        'flex-none h-auto fixed z-10 top-0 inset-x-0 w-full transition-all duration-75 group'
      )}
    >
      {props.children}
      <div
        className={cn(
          scrollPosition > 0 ? 'opacity-100' : 'opacity-0',
          'flex flex-none flex-row flex-nowrap content-center items-center justify-center h-px relative w-full transition-all'
        )}
      >
        <div className='flex-[1_0_0px] h-px overflow-visible relative w-px glow-left' />
        <div className='flex-[1_0_0px] h-px overflow-visible relative w-px glow-right' />
      </div>
    </header>
  )
}