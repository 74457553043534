'use client'

import * as React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

// import { MobileNav } from "@/components/mobile-nav"
import Image from 'next/image'
import classNames from 'classnames'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger
} from '@/components/ui/navigation-menu'
import { siteConfig } from '@/config/site'
import { MainNavItem } from '@/types/config'
import { Button } from './ui/button'
import { ArrowRight, ArrowUpRight, Forward, Menu, Send, X } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion'

interface NavItemProps {
  item: MainNavItem
}

interface MainNavProps {
  items?: MainNavItem[]
  children?: React.ReactNode
}

function NavItem(props: NavItemProps) {
  if (props.item.subNav && props.item.subNav.length) {
    return (
      <NavigationMenuItem>
        <NavigationMenuTrigger
          className={classNames(
            'flex items-center text-lg font-medium transition-colors text-foreground sm:text-base p-0 h-auto bg-transparent hover:bg-transparent data-[state=open]:bg-transparent data-[active]:bg-transparent',
            // props.item.href.startsWith(`/${segment}`) ? 'text-foreground' : 'text-foreground',
            props.item.disabled && 'cursor-not-allowed opacity-80'
          )}
        >
          {props.item.title}
        </NavigationMenuTrigger>
        <NavigationMenuContent className='bg-white'>
          <ul className='w-[200px] gap-3 p-2'>
            {props.item.subNav.map((component) => (
              <ListItem key={component.title} title={component.title} href={component.href}>
                {component.description}
              </ListItem>
            ))}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    )
  } else {
    return (
      <NavigationMenuItem>
        <Link href={props.item.href} legacyBehavior passHref>
          <NavigationMenuLink
            className={classNames(
              'flex items-center text-lg font-medium transition-colors text-foreground hover:text-foreground/80 sm:text-base',
              // props.item.href.startsWith(`/${segment}`) ? 'text-foreground' : 'text-foreground/60',
              props.item.disabled && 'cursor-not-allowed opacity-80'
            )}
          >
            {props.item.title}
          </NavigationMenuLink>
        </Link>
      </NavigationMenuItem>
    )
  }
}

function MobileNavItem(props: NavItemProps) {
  if (props.item.subNav && props.item.subNav.length) {
    return (
      <Accordion type='single' collapsible>
        <AccordionItem value={props.item.href}>
          <AccordionTrigger className='p-4 pr-2 text-xl font-medium !no-underline'>
            {props.item.title}
          </AccordionTrigger>
          <AccordionContent className='p-0'>
            {props.item.subNav.map((item) => (
              <Link key={item.href} href={item.href} className='flex p-4 text-lg font-medium'>
                {item.title}
              </Link>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  } else {
    return (
      <Link href={props.item.href} className='p-4 text-xl font-medium border-b border-border'>
        {props.item.title}
      </Link>
    )
  }
}

export function MainNav({ items, children }: MainNavProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false)
  const pathname = usePathname()
 
  React.useEffect(() => {
    setShowMobileMenu(false)
  }, [pathname])

  return (
    <>
      <nav className='transition-all mx-auto flex max-w-full sm:max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8'>
        <div className='flex lg:flex-1'>
          <Link href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>{siteConfig.name}</span>
            <Image
              className='h-9 w-auto'
              src='/images/logo.svg'
              alt='Helios SMS Marketing'
              width={356.4}
              height={140.96}
            />
          </Link>
        </div>
        <div className='hidden lg:flex lg:gap-x-12'>
          <NavigationMenu>
            <NavigationMenuList className='flex gap-x-10'>
              {items?.length ? items.map((item) => <NavItem item={item} key={item.href} />) : null}
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className='flex flex-1 items-center justify-end gap-x-3 sm:gap-x-6'>
          <Link href='/login' className='hidden lg:block lg:text-sm lg:leading-6 hover:underline'>
            Log in
          </Link>
          <Link href='/book-a-demo' className='hidden lg:block lg:text-sm lg:leading-6 hover:underline'>
            Book a demo
          </Link>
          <Button size='sm' asChild className='group/button transition-all'>
            <Link href='/signup' className='px-3 py-2'>
              Get started
              <ArrowRight className='w-3 h-3 ml-2 relative transition-transform group-hover/button:translate-x-0.5' />
            </Link>
          </Button>
          <Button
            size='sm'
            className='w-9 shadow-none lg:hidden'
            variant='ghost'
            onClick={() => setShowMobileMenu((open) => !open)}
          >
            {showMobileMenu ? (
              <X className='w-5 h-5 shrink-0' />
            ) : (
              <Menu className='w-5 h-5 shrink-0' />
            )}
          </Button>
        </div>
      </nav>
      <div
        className={cn(
          showMobileMenu ? 'flex flex-col' : 'hidden',
          'lg:hidden h-[calc(100vh-85px)] absolute top-[85px] z-10 bg-background w-full px-6 pt-4 pb-20 border-t border-border'
        )}
      >
        <nav className='flex flex-col grow'>
          {items?.length
            ? items.map((item) => <MobileNavItem item={item} key={item.href} />)
            : null}
          <Link href='/login' className='p-4 text-xl font-medium border-b border-border'>
            Log in
          </Link>
        </nav>

        <div className='flex flex-col gap-4'>
          {/* <Button size='xl' variant='outline' asChild className='w-full'>
            <a href='sms:+16468460076?&amp;body=Hey%20Helios%21%20Let%27s%20talk'>
              <img src='/images/icon.svg' className='w-4 h-4 mr-3' />
              Send us a text
            </a>
          </Button> */}
          <Button size='xl' variant='outline' asChild className='w-full'>
            <Link href='/book-a-demo'>Book a demo</Link>
          </Button>
          <Button size='xl' asChild className='w-full'>
            <Link href='/signup'>Get started</Link>
          </Button>
        </div>
      </div>
    </>
  )
}
const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={classNames(
              'block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors  hover:bg-gray-100 focus:bg-gray-10',
              className
            )}
            {...props}
          >
            <div className='text-sm font-medium leading-none'>{title}</div>
            <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>{children}</p>
          </a>
        </NavigationMenuLink>
      </li>
    )
  }
)
ListItem.displayName = 'ListItem'
